import React from "react";

const Subscribe = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {/* App Store Badge */}
            <a className="store-badge"
              href="https://apps.apple.com/us/app/magic-friends/id6497877176"
              target="_blank"
              rel="noopener noreferrer"
              >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`}
                alt="Download on the App Store"
                style={{ 
                  width: '120px',
                  height: '40px',
                  objectFit: 'contain'
                }}
              />
            </a>
            {/* Google Play Badge */}
            <a className="store-badge"
              href="https://play.google.com/store/apps/details?id=com.magiclabs.magicfriends"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/google-play-badge-logo-svgrepo-com.svg`}
                alt="Get it on Google Play"
                style={{ 
                  width: '135px',
                  height: '40px',
                  objectFit: 'contain'
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;