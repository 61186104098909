import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";

const Menu = () => {


  return (
    <Navbar
      id="Navbar"
      className="navbar navbar-expand-lg navbar-light theme-nav"
      style={{
        background: 'linear-gradient(to right, rgb(255, 89, 46), rgb(255, 171, 46))',
        height: '60px',
      }}
    >
      <div id="navbar-main" className="container d-flex justify-content-between align-items-center h-100">
        <NavbarBrand className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Logo_white.png`}
            alt="logo"
            style={{ height: '40px' }}
            
          />
        </NavbarBrand>
        <button
          className="btn p-0 border-0"
          style={{
            background: 'transparent',
            height: '40px',
          }}
        
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/rocket.png`}
            alt="Sign Up"
            style={{ height: '100%' }}
            className="spinning-logo"
          />
        </button>
      </div>
    </Navbar>
  );
};

export default Menu;