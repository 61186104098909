import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) };
  }, []);

  // Set the background image
  useEffect(() => {
    const bgImg = localStorage.getItem("backgroundImage");
    if (bgImg) {
      document.querySelector(".copyright-section").style.backgroundImage = `url(${bgImg})`;
    } else {
      document.querySelector(".copyright-section").style.backgroundImage = `url(${process.env.PUBLIC_URL}assets/images/home3/home3-slider.png)`;
    }
  }, []);

  return (
    <div>
      {/* Other footer content */}

      <div className="copyright-section index-footer">
        <p>2023 copyright by Magic Friends || Magic Labs</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/* Your customer chat code */}
      <div
        className="fb-customerchat"
        page_id="2123388804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;