import React, { useEffect, useState } from "react";
import "./About.css"; // Add this import for the styles

const About = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`,
      });
  }, [color]);

  return (
    <section id="about" className="about" style={bgImg}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="about-content">
              <h1 className="title">
                A Virtual Companion <br></br>for Learning & Fun
              </h1>
            
              <div className="features">
                <div className="feature">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon1.png`} alt="Customize Dialog" />
                 
                </div>
                <div className="feature">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon3.png`} alt="Easy to Use" />
                  
                </div>
                <div className="feature">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon2.png`} alt="Modern Design" />
                  
                </div>
                <div className="feature">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon4.png`} alt="Modern Design" />
                  
                </div>
              </div>
              <p className="description">
                Magic Friends allows your child to explore, learn and play with the help of a state-of-the-art Artificial Intelligence companion. The system focuses on providing a safe, child-appropriate experience in which children can learn about the world around them in a fun an engaging way. There are no limits to the amount of knowledge that Magic Friends can provide, while doing so in a very fun and unique way through the use of virtual cartoon style avatars.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;